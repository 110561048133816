import React from "react";
import CardItem from "../../Common/CardItem";

import managementTeam from "../../../data/About/managementTeam.json";
import seniorLeaderData from "../../../data/About/seniorleadersData.json";
import emerginLeaderData from "../../../data/About/emergingLeaders.json";

function TeamCard() {
  return (
    <div className="blog-area-wrapper sm-top">
      <div className="container">
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          {" "}
          Management Team
        </h2>
        <br />
        <div className="row mtn-35">
          {managementTeam.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              subtitle={item.subtitle}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              cols="col-md-6 col-lg-6"
              socialLinks={item.socials}
              readmore="false"
              style={{ padding: "0px 30px 45px" }}
            />
          ))}
        </div>
        <br />
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          Meet Senior Leaders
        </h2>
        <br />
        <div className="row mtn-35">
          {seniorLeaderData.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              subtitle={item.subtitle}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              cols="col-md-6 col-lg-3"
              socialLinks={item.socials}
              style={{ padding: "0px 30px 45px" }}
            />
          ))}
        </div>
        <br />
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          Meet Emerging Leaders
        </h2>
        <br />
        <div className="row mtn-35">
          {emerginLeaderData.map((item) => {
            if (item.id <= 4) {
              return (
                <CardItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  excerpt={item.excerpt}
                  thumb={item.thumb}
                  height={item.height}
                  width={item.width}
                  cols="col-md-6 col-lg-3 "
                  socialLinks={item.socials}
                  style={{ padding: "0px 30px 45px" }}
                />
              );
            } else {
              return (
                <CardItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  excerpt={item.excerpt}
                  thumb={item.thumb}
                  height={item.height}
                  width={item.width}
                  cols="col-md-6 col-lg-4 "
                  socialLinks={item.socials}
                  style={{ padding: "0px 30px 45px" }}
                />
              );
            }
          })}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default TeamCard;
