import React from "react";
import FormInput from "../../components/UI/Input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const From = () => {
  const sendEmail = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email_address.value;
    const msg = event.target.con_message.value;
    const emailBody = `<h2>Team,</h2> <h2>We have new customer enquiry from Fubeus website. Please contact and verify. Below are information, </h2> <h2> Customer Name :  ${name} </h2>
                            <h2>Email ID : ${email} </h2>
                            <h2>Message : ${msg} </h2>
                            <h2>Thanks,<br/> Fubeus support </h2>`;
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "no-reply@fubeus.com",
      Password: "B557C7C54E958B09A80650AE50BB59BD4041",
      To: "contact@fubeus.com",
      From: "no-reply@fubeus.com",
      Subject: "Product enquiry from website",
      Body: emailBody,
    }).then(function (message) {
      if (message === "OK") {
        toast.success(
          "Thanks for writing us. Our Team will contact you shortly!",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      } else {
        toast.error("Something went worng. Please try again. !", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      document.getElementById("contact-form").reset();
    });
  };
  return (
    <div className="contact-form-wrap">
      <form id="contact-form" onSubmit={sendEmail}>
        <div className="row">
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"name"}
              placeholder={"Your Name *"}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"email"}
              name={"email_address"}
              placeholder={" Your Email*"}
            />
          </div>

          <div className="col-12">
            <FormInput
              tag={"textarea"}
              name={"con_message"}
              placeholder={"Please share your Requirements"}
            />

            <FormInput tag={"button"} classes={"btn-outline"} />

            <div className="form-message" />
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default From;
